.root {
  position: sticky;
  top: 0;
  z-index: 100;
  width: 100%;
  padding: 0;
}

.headerActions {
  display: flex;
  align-items: center;
  padding: 0 10px;
  position: relative;
  justify-content: space-between;
}

.trigger {
  font-size: 18px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.headerRightActions {
  display: flex;
  justify-content: space-between;
  width: 10rem;
  align-items: center;
}
.headerSelector {
  width: 8rem;
  margin-left: 0.5rem;
}
