.balanceLogHeader {
  margin-bottom: 2rem;
}

.datePicker {
  margin-bottom: 1rem;
}

.customHeader {
  background-color: #dedede !important;
}
th {
  background-color: #efefef !important;
}
