.messageColumnsSuccess {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(51, 194, 32);
  width: 1rem;
  height: 1rem;
}
.messageColumnsError {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 77, 79);
  width: 1rem;
  height: 1rem;
}

.messageColumns {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.difference {
  padding-left: 10px;
}

.childRowColor {
  background-color: rgb(241, 235, 235);
}
