.header {
  display: flex;
  justify-content: space-between;
}

.withdrawLimit {
  display: flex;
  justify-content: space-between;
}

.editButton {
  width: 60px;
  height: 40px;
}
