.dailyEditModalCard {
  margin-top: 1.5rem;
}
.dailyEditModalconfirmButtonsBody {
  margin-top: 2rem;
}
.dailyEditModalConfirmButton {
  margin-right: 2rem;
}

.dailyEditModalInputTitle {
  display: inline-block;
  width: calc(50% - 8px);
}
.dailyEditModalInputBody {
  display: inline-block;
  width: calc(50% - 8px);
  margin: 0 8px;
}
.dailyEditModalTFTdailyEditModalTFT {
  width: calc(100% - 8px);
}

.gamesInput {
  width: 100%;
}
