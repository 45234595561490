.playerInfoBody {
  :global(.ant-tabs-tab) {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  :global(.ant-tabs-nav-list) {
    width: 99.9%;
  }
}

.playerInfoSize {
  font-size: 17px;
  font-weight: bold;
}
