.modalCol {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 2rem;
}

.movedUserInfo {
  margin-top: 5px;
  border-radius: 10px;
  height: 100%;
}

.verticalDivider {
  width: 0.1%;
  background-color: #d9d9d9;
  margin-right: 2%;
}

.userInfoData {
  font-weight: bold;
  margin-left: 10px;
}

.userInfoDataNew {
  font-weight: bold !important;
  display: inline-block;
  vertical-align: middle;
}
