.tableStatus {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 7rem;
  height: 2rem;
}

.manual {
  background-color: rgb(249, 249, 167);
}
.check {
  width: 2.2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkAaEnabled {
  width: 2.2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(
    to right,
    rgb(255, 77, 79) 50%,
    lightGrey 50%
  );
}

.greenWithGrey {
  width: 2.2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(
    to right,
    rgb(51, 194, 32) 50%,
    lightGrey 50%
  );
}

.trxTabBody {
  :global(.ant-table-cell-row-hover) {
    background-color: inherit !important;
  }
}

.childRowColor {
  background-color: rgb(241, 235, 235);
}
