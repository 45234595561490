.sidebar {
  position: fixed !important;
  top: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  height: 100%;
  z-index: 101;
}

.sidebarContent {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.sidebarMenu {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
}
