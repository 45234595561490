.sidebarHeader {
  position: relative;
  width: 100%;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
}

.closeIconWrapper {
  position: absolute;
  right: 10px;
}

.closeIcon {
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  color: white;
}
