.root {
  height: 100%;

  &.authPage {
    height: unset;
    :global(.ant-card-head) {
      text-align: center;
    }
  }
}

.form {
  max-width: 300px;
  margin: 0 auto;
}

.loginButton {
  width: 100%;
}

.secretTokenSwitcher {
  margin-right: 10px;
}

.title {
  text-align: center;
  margin: 0;
}
