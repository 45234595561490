.form {
  max-width: 300px;
}

.loginButton {
  width: 100%;
}

.secretTokenSwitcher {
  margin-right: 10px;
}
