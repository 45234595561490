.notesHeader {
    width: 100%;
    display: flex ; 
    justify-content: space-between ;
    align-items: center;
    padding-bottom: 5px;
}

.notesContent {
    height: 15rem;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%,
  }

.createNotePopup {
    position: absolute;
    width: 380px;
}

.notesCenter {
    display: flex;
    justify-content: space-between;
    align-items: center;
}