.trxTabBody {
  :global(.ant-table-cell-row-hover) {
    background-color: inherit !important;
  }
}
.blue {
  background-color: rgb(107, 163, 248);
}

.red {
  background-color: rgb(240, 71, 71);
}

.green {
  background-color: rgb(127, 213, 127);
}

.yellow {
  background-color: rgb(235, 235, 86);
}

.gray {
  background-color: rgb(146, 144, 144);
}
