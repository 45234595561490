.generalTableHeader {
  background: #fafafa;
  border-radius: 2px;
  align-items: center;
}

.generalTableTitle {
  height: 3rem;
  border: 1px solid #f5f5f5;
  font-weight: 600;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  align-items: center;
  padding-left: 1rem;
}

.generalTableBody {
  height: 3rem;
  border: 1px solid #f5f5f5;
  display: flex;
  align-items: center;
  padding-left: 1rem;
}
