.messagesTabDeleteButton {
  width: 4.5rem;
  color: white;
  background-color: red;
}
.messagesTabEditButton {
  width: 4.5rem;
  margin-right: 1rem;
}
.messagesTabCheckOutlined {
  color: green;
  display: flex;
  justify-content: center;
}
.messagesTabCloseOutlined {
  color: red;
  display: flex;
  justify-content: center;
}
