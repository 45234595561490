.sidebar {
  position: fixed !important; // Change style of ant design
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  height: 100%;
  z-index: 103;
}

.sidebarContent {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.sidebarHeader {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  padding: 0 10px;
}

.headerTitle {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
  color: black;
}

.notificationsRoot {
  flex: 1;
  overflow: hidden;
  background-color: var(--color-background);
}

.closeIcon {
  font-size: 1rem;
  cursor: pointer;
  color: black;
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
  opacity: 0;
  transition: opacity 0.2s;

  &.open {
    z-index: 102;
    opacity: 1;
  }
}
