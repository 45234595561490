.sessionId {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  color: #1890ff;
  text-decoration: underline;
}

.sessionId:hover {
  text-decoration: underline;
}

.scrollRow {
  background-color: #dedede !important;
}
